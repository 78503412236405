<template>
  <div class="dashboard">
    <div class="data-card">
      <v-card 
        class="card__week-work-hour elevation-0"
        tile
      >
        <div class="card-content">
          <p class="card-title">금주 총 조업시간</p>
          <p class="card-data">
            {{timeFormatter(data.work_hour)[0]}}<span class="small-txt">시간</span>
            {{timeFormatter(data.work_hour)[1]}}<span class="small-txt">분</span>
          </p>
        </div>
      </v-card>
      <v-card 
        class="card__complete-soon-machine elevation-0"
        tile
      >
        <div class="card-content">
          <p class="card-title">공정 완료 임박 기계</p>
          <p class="card-data">
            <template v-if="data.complete_soon_machine != null">{{ data.complete_soon_machine }}</template>
            <template v-else>&nbsp;</template>
          </p>
        </div>
      </v-card>
      <v-card class="card__unmatched-process elevation-0"
        tile
      >
        <div class="card-content">
          <div class="card-content__left">
            <p class="card-title">금주 미배치 공정</p>
            <p class="card-data">{{ data.unmatched_process }}<span class="small-txt">개</span></p>
          </div>
          <div class="card-graph">
            <bars
              :width="230"
              :height="120"
              :data="data.unmatched_processes"
              :gradient="['#bcd4df', '#bcd4df']"
              :barWidth="20"
              :rounding="0.1"
              labelColor="#bcd4df"
              :labelRotate="0"
              :labelSize="0.8"
              :labelData="getLabels(data.unmatched_processes)"
              :growDuration="1">
            </bars>
          </div>
        </div>
      </v-card>
    </div>
    <div class="machine-schedule-timeline">
      <loading 
        id="loading"
        :active.sync="visible" 
        :can-cancel="true" 
        :is-full-page="true"
        color="#48627b" 
        loader="dots" 
      ></loading>
      <apexchart
        ref="timeline"
        type="rangeBar"
        class="timeline"
        :options="machine_schedule.options"
        :series="machine_schedule.data"
        :height="machine_schedule.height"
      >
      </apexchart>
    </div>
    <div class="notice-list">
      <ul class="notices">
        <v-menu
          content-class="notice-hover-tooltip"
          v-model="notice.hover.open"
          :position-x="notice.hover.x"
          :position-y="notice.hover.y"
          dark
        >
          <v-card class="inner">
            <p class="notice-text--all">
              {{ notice.hover.data }}
            </p>
          </v-card>
        </v-menu>
        <li 
          v-for="n,index in notice.data"
          :key="'notice-' + n.date + '-' + index"
          class="notice"
          :class="{ 
            'notice--selected': index == notice.selected,
          }"
          @click="clickNotice(n, index)"
          @mouseover="(event) => hoverNotice(event, n)"
          @mouseleave="notice.hover.open = false"
        >
          <span class="notice-date">{{ dateFormatter(n.date) }}</span>
          <span class="notice-day" :class="{ 'mac': is_mac, }">{{ getDayName(n.date) }}</span>
          <span class="notice-text">{{ n.noti }}</span>

          <div class="notice-actions" v-if="index == notice.selected">
            <v-btn 
              class="notice-actions__btn notice-modify"
              @click="clickNoticeModify"
              fab x-small depressed text
            >
              <v-icon size="18">$modify</v-icon>
            </v-btn>
            <v-btn 
              class="notice-actions__btn notice-delete"
              @click="clickNoticeDelete"
              fab x-small depressed text
            >
              <v-icon size="18">$deleteIcon</v-icon>
            </v-btn>
          </div>
        </li>
        <li 
          v-if="notice.data.length == 0"
          class="notice notice--no-data"
        >
          <span class="no-data">공지사항이 없습니다</span>
        </li>
        <li class="notice notice-add">
          <v-btn 
            :disabled="visible"
            @click='clickNoticeAdd'
            :ripple="false"
            plain block
          >
            <v-icon>$addCircle</v-icon>
          </v-btn>
        </li>
      </ul>

      <!-- 공지사항 수정 / 삭제 dialog -->
      <v-dialog
        v-model="notice.open"
        content-class="notice__dialog"
        @click:outside="cancleNotice"
        persistent dark
      >
        <v-card class="inner">
          <p class="dialog-title">
            공지사항 
            <template v-if="notice.mode == 'add'">등록</template>
            <template v-if="notice.mode == 'modify'">수정</template>
          </p>
          <div class="dialog__content">
            <label class="dialog__form-label">
              <span class="label-text">날 짜</span>
              <dayPicker 
                class="label-field"
                :presetIcon="['prepend', false, true]"
                :preset="notice.date"
                :clickPick="true"
                :fieldHeight="38"
                @picked="setDate"
              >
              </dayPicker>
            </label>
            <v-textarea 
              class="dialog-textarea elevation-0 rounded-0"
              v-model="notice.value"
              height="150"
              solo light hide-details
            >
            </v-textarea>
          </div>
          <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac }">
            <v-btn 
              class="action-btn notice-cancle" 
              light
              @click="cancleNotice"
            >취소</v-btn>
            <v-btn 
              v-if="notice.mode == 'add'" 
              class="action-btn notice-add" 
              @click="addNotice"
              light
            >등록</v-btn>
            <v-btn 
              v-if="notice.mode == 'modify'" 
              class="action-btn notice-modify" 
              @click="modifyNotice"
              light
            >수정</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 공지 삭제 confirm -->
      <v-dialog 
        content-class="notice-delete-confirm" 
        v-model="notice.confirm"
        persistent dark
      >
        <v-card class="inner">
          <p class="dialog-title">공지사항 삭제</p>
          <div class="dialog__content">
            <p class="dialog__confirm-text">
              <strong>{{ notice.date }}</strong>에 등록된<br>
              공지사항을 삭제하시겠습니까?
            </p>
          </div>
          <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac }">
            <v-btn class="action-btn cancle-notice-delete" @click="notice.confirm = false" light>취소</v-btn>
            <v-btn class="action-btn confirmed-notice-delete" @click="deleteNotice" light>확인</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </div>
    <div class="mini-calendar">
      <p class="calendar-title">{{ today.substr(0, 7).replace('-', '.') }}</p>
      <v-calendar
        type="month"
        height="200"
        v-model="today"
        color="#98b6c2"
        :show-month-on-first="false"
        :weekdays="[0, 1, 2, 3, 4, 5, 6, ]"
        :weekday-format="weekdayFormatter"
      >
      </v-calendar>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import moment from 'moment';
import loading from 'vue-loading-overlay';
import Vue from 'vue';
import bars from 'vuebars';
import dayPicker from '../components/dayPicker.vue';
Vue.use(bars);

export default {
  components: {
    loading,
    apexchart,
    moment,
    bars,
    dayPicker,
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      data: {
        work_hour: null,
        complete_soon_machine: null,
        unmatched_process: null,
        unmatched_processes: [
          {
            value: 0,
            title: '일',
            full_name:'Sunday'
          },
          {
            value: 0,
            title: '월',
            full_name:'Monday'
          },
          {
            value: 0,
            title: '화',
            full_name:'Tuesday'
          },
          {
            value: 0,
            title: '수',
            full_name:'Wednsday'
          },
          {
            value: 0,
            title: '목',
            full_name:'Thursday'
          },
          {
            value: 0,
            title: '금',
            full_name:'Friday'
          },
          {
            value: 0,
            title: '토',
            full_name:'Saturday'
          },
        ]
      },
      machine_schedule: {
        data: [],
        options: {
          noData: {
            text: 'NoData',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          },
          chart: {
            type: 'rangeBar',
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '55%',
              rangeBarGroupRows: true,
            }
          },
          fill: {
            type: 'solid',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC:false,
              datetimeFormatter: {
                year: 'yyyy',
                month: 'yy/MM', 
                day: 'MM/dd',
                hour: 'HH:mm',
              },
              style: {
                fontSize: '14px',
              }
            },
          },
          yaxis: {
            labels: {
              align: 'left',
              style: {
                fontSize: '16px',
              }
            }
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
            offsetX: 24,
            formatter: function(val, opt) {
              let label = opt.w.config.series[opt.seriesIndex].name;
              return label;
            }
          },
          tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}){
              const data = w.config.series[seriesIndex];
              const program = data.name;
              const start = data.data[0].y[0];
              const end = data.data[0].y[1];

              let tooltip = '';
              tooltip += '<div class="tooltip-schedule">';
              tooltip += '<p class="tooltip-title">' + program + '</p>';
              tooltip += '<p class="tooltip__content">';
              tooltip += moment(start).format('YYYY-MM-DD HH:mm:ss')
              tooltip += ' ~ ';
              tooltip += moment(end).format('YYYY-MM-DD HH:mm:ss')
              tooltip += '</p>';
              tooltip += '</div>';
              return tooltip;
            }
          },
          grid: {
            show: true,
            xaxis: {
              lines: {
                show: true,
              }
            }
          },
          colors: [ 
            ({seriesIndex, dataPointIndex, value, w}) => {
              const data = w.config.series[seriesIndex];
              const sche = this.machine_schedule;
              var mid;
              data.data.length > 0 ? mid = data.data[0].x : undefined;
              const program = data.name;
              if( program == undefined ) return '#999999'
              const key = data.data[0].y[0];
              const mid_i = sche.mids.indexOf(mid);
              const programs = sche.trimed[mid];
              const program_i = programs.indexOf(program + '-' + key);

              const first = mid_i % 5;
              const second = program_i % 4;
              return this.colorchip[first][second];
            }
          ],
        },
        height: 300,
        trimed: {},
        mids: [],
      },
      notice: {
        data: [],
        selected: undefined,
        open: false,
        confirm: false,
        date: moment().format('YYYY-MM-DD'),
        mode: 'add',
        value: '',
        hover: {
          data: '',
          open: true,
          x: 0,
          y: 0,
        }
      },
      visible: false,
    };
  },
  computed: {
    colorchip() {
      return this.$colorchip;
    },
    is_mac() {
      return this.$is_mac;
    },
  },
  methods: {
    getUnmatch(){
      this.$http.APS.get('/main/count').then(result=>{
        if (result.data.length==0){
          this.data.unmatched_process = 0;
        }else{
          this.data.unmatched_process = result.data[0].count;
        } 
      })
    },
    weekMatch(){
      this.$http.APS.get('/main/week').then(result=>{
        const data = this.data.unmatched_processes;
        if(result.data.length!=0){
          for(let i = 0; i < result.data.length; i++){
            for(let j = 0; j < data.length; j++){
              if(data[j].full_name.indexOf(result.data[i].dayname)!= -1){
                data[j].value = result.data[i].value;
              }
            }
          }
        }
      })
    },
    getData(){
      this.visible = true;
     
      this.$http.APS.get('/main/program').then(result=>{
        let array = [] ;
        const sche = this.machine_schedule;
        for(let i = 0; i < result.data.length; i++){
          const data = result.data[i];
          data.start = this.dateTimeFormatter(data.start);
          data.end = this.dateTimeFormatter(data.end);
          array.push({ name: data.name,
            data:[
              {
                x : data.mid,
                y : [
                  new Date(data.start).getTime(),
                  new Date(data.end).getTime(),
                ]
              }
            ]
          })
        }
        sche.data = array;
        this.setProgramGroup(sche.data);
        this.visible = false;
      })
    },
    setDate(v){
      this.notice.date=v;
    },
    getMid(){
      this.$http.APS.get('/main/lotend').then(result=>{
        if(result.data.length != 0){
          this.data.complete_soon_machine = result.data[0].mid;
        }
      })
    },
    setProgramGroup(data) {
      const sche = this.machine_schedule;
      let group = {};

      for( let i = 0; i < data.length; i++ ) {
        const tg = data[i];
        const program = tg.name;
        const mid = tg.data[0].x;
        const key = tg.data[0].y[0];

        if( group[mid] == undefined ) group[mid] = [];
        group[mid].push( program + '-' + key );
      }
      sche.trimed = group;
      sche.mids = Object.keys(group).sort();
      this.getGraphHeight(sche.mids);
    },
    getGraphHeight(mids) {
      const value = mids.length * 40 + 60;
      this.machine_schedule.height = value < 300 ? 300 : value;
      return value < 300 ? 300 : value;
    },
    getDayName(date) {
      const preset = {
        Mo: '월', Tu: '화',We: '수',
        Th: '목', Fr: '금',Sa: '토', Su: '일',
      };
      return preset[moment(date).format('dd')];
    },
    getLabels(data) {
      let labels = [];
      for( let i = 0; i < data.length; i++ ) {
        labels.push(data[i].title);
      }
      return labels;
    },
    getTime(){
      this.$http.APS.get('/main/').then(result=>{ 
        this.data.work_hour = result.data[0].sum;
      })
    },
    getNotice(){
      this.$http.APS.get('/main/noti').then(result=>{
        this.notice.data = result.data;
      })
    },
    clickNotice(data, index) {
      const notice = this.notice;
      notice.selected == index ? notice.selected = undefined : notice.selected = index;
      notice.value = data.noti;
      notice.date = moment(data.date).format('YYYY-MM-DD');
      notice.id = data.id; 
    },
    clickNoticeModify() {
      const notice = this.notice;
      notice.open = true;
      notice.mode = 'modify';
    },
    clickNoticeDelete() {
      const notice = this.notice;
      notice.confirm = true;
    },
    clickNoticeAdd() {
      const notice = this.notice;
      notice.open = true;
      notice.mode = 'add';
    },
    async modifyNotice() {
      const notice = this.notice;
      var map ={date:notice.date,noti:notice.value,id:notice.id};
      await this.$http.APS.patch('/main/',map);
      await this.getNotice();
      await this.cancleNotice();
    },
    async deleteNotice() {
      const notice = this.notice;
      await this.$http.APS.delete('/main/'+notice.id);
      await this.getNotice();
      notice.confirm = false;
    },
    cancleNotice() {
      const notice = this.notice;
      notice.open = false;
      notice.value = '';
      notice.date = this.today;
    },
    async addNotice() {
      const notice =this.notice;
      var map ={date:notice.date,noti:notice.value};
      await this.$http.APS.post('/main/',map);
      await this.getNotice();
      await this.cancleNotice();
    },
    timeFormatter(v){
      const hour = parseInt(v / 60);
      const minutes = parseInt(v % 60);
      return [ hour, minutes ]
    },
    dateTimeFormatter(v){
      v = v.substr(0,19).replace('T',' ');
      return v;
    },
    dateFormatter(v){
      v = v.substr(0,10).replace('T',' ');
      return v;
    },
    weekdayFormatter(v) {
      const preset = [ '일', '월', '화', '수', '목', '금', '토'];
      return preset[v.weekday];
    },
    hoverNotice(event, data) {
      let notice = this.notice.hover;
      notice.data = data.noti;
      notice.open = true;
      notice.x = event.clientX;
      notice.y = event.clientY;
    }
  },
  mounted(){
    this.getTime();
    this.getNotice();
    this.getMid();
    this.getData();
    this.getUnmatch();
    this.weekMatch();
  },
  watch:{
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/global.scss';

  .dashboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: 
      "card card card card"
      "schedule schedule schedule schedule"
      "notice notice notice calendar";
    width: 100%;
    height: 100%;
    padding: 70px 45px;

    .data-card {
      @extend .flex_row;

      grid-area: card;
      justify-content: stretch;
      width: 100%;

      .v-card {
        flex: 1 1 33.333%;
        margin-right: 24px;
        padding: 18px 32px;
        font-weight: 500;
        background-color: $skyblue-lighter1;

        &:last-child {
          margin-right: 0;
        }

        .card-title {
          font-size: 20px;
        }
        .card-data {
          font-size: 55px;
          line-height: 0.8;
          padding-top: 0.535vw;
          margin-top: 24px
        }
        .small-txt {
          font-size: 30px;
          position: relative;
          bottom: 2px;
        }

        &.card__week-work-hour {
          background: $skyblue-lighter1 url(../assets/img/work_hour.svg) right 32px center no-repeat;
          background-size: 85px auto; 
        }
        &.card__complete-soon-machine {
          background: $skyblue-lighter1 url(../assets/img/machine.svg) right 32px center no-repeat;
          background-size: auto 74px; 
        }
        &.card__unmatched-process {
          
          .card-content {
            @extend .flex_row;

            justify-content: stretch;

            &__left {
              flex: 0 0 auto;
            }
          }
          .card-graph {
            flex: 1 1 auto;
            position: relative;

            svg {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0, -45%);

              ::v-deep .x-axis {
                transform: translate(10px, 110px);
              }
            }
          }
        }
      }
    }

    .machine-schedule-timeline {
      grid-area: schedule;
      margin-bottom: 24px;
      position: relative;

      ::v-deep #loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        .vld-background {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
        .vld-icon {
          @extend .flex_row;

          height: 100%;
          justify-content: center;
          position: relative;
          z-index: 1;
        }
      }
    }

    .notice-list {
      grid-area: notice;
      padding-right: 12px;

      .notices {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px 10px;

        .notice {
          @extend .flex_row;

          justify-content: stretch;
          width: 100%;
          max-width: 100%;
          height: 50px;
          border-radius: 30px;
          line-height: 1;
          padding: 0 32px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          background-color: $skyblue-lighter2;

          &:nth-child(4n),
          &:nth-child(4n+1) {
            background-color: $skyblue-darken1;

            .notice-day {
              background-color: #8AAFBF;
            }
          }

          &.notice--selected {
            background-color: #B9C0C3;

            .notice-day {
              background-color: #9BACB4;
            }
          }

          span {
            font-size: 20px;
            padding-top: 2px;
          }
          .notice-date {
            flex: 0 0 auto;
          }
          .notice-day {
            flex: 0 0 auto;
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            padding-top: 10px;
            margin: 0 32px 0 16px;
            text-align: center;
            background-color: #BCD4DF;

            &.mac {
              padding-top: 12px;
            }
          }
          .notice-text {
            @extend .ellipsis;

            flex: 1 1 0;
            height: 32px;
            line-height: 32px;
          }
        }
        .notice-actions {
          padding: 10px 16px ;
          position: absolute;
          top: 0;
          right: 0;

          &__btn {
            width: 30px;
            height: 30px;
            max-width: unset;
            
            ::v-deep .v-icon {
              position: relative;
              top: -1px;

              .cls-1 {
                fill: black !important;
              }
            }
          }
        }
        .notice-add {
          
          .v-btn {
            background-color: transparent !important;
            padding-bottom: 2px;
          }
        }
      }
    }

    .mini-calendar {
      @extend .flex_column;

      grid-area: calendar;
      margin-left: 12px;
      overflow: hidden;
      border: 1px solid $skyblue-darken1;
      border-radius: 12px;

      .calendar-title {
        font-size: 20px;
        font-family: $ptd;
        text-align: center;
        padding: 18px 0;
      }

      ::v-deep .v-calendar {
        border: 0 !important;

        &-weekly {
          
          .v-calendar-weekly__head {

            &-weekday {
              color: black !important;

              &:first-child { color: $cal_sunday !important; }
              &:last-child { color: $cal_saturday !important; }
            }
          }

          .v-calendar-weekly {

            &__head-weekday {
              border: 0 !important;

              &.v-outside {
                background-color: transparent;
              }

            }
          }

          .v-calendar-weekly__week { // 주
 
            .v-calendar-weekly__day { // 일(하루)
              border: 0 !important;

              &:first-child { // 일

                .v-btn__content {
                  color: #8caab4;
                }
              }
              &:last-child { // 토

                .v-btn__content {
                  color: #666666;
                }
              }

              &.v-outside {
                opacity: 0.2;
                background-color: white !important;
              }
            }
          }

        }
      }
    }
  }

  // 공지사항 dialog
  .notice__dialog {
    @extend .dialog--black-theme;
  }
  .notice-delete-confirm {
    @extend .dialog--black-theme;
  }
  // 공지사항 마우스 오버 tooltip
  .notice-hover-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    .inner {
      max-width: 420px;
      padding: 12px 16px;
      background: transparent !important;
    }
  }

  // machine schedule range bar(timeline) tooltip
  .tooltip-schedule {
    @extend .tooltip--black-theme;
  }
</style>